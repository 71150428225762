
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}


.plan-highlight-box{
  width: 115px;
  height: 97px;
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  position: absolute;
  top: 15px;
  overflow: hidden;
  text-align: center;
  z-index: 0;
  right: 15px;
}
.plan-highlight {
  font-size: 10px;
  color: white;
  background: #28a745;
  text-transform: uppercase;
  padding: 58px 0px 5px 50px;
  width: 180px;
  text-align: center;
  line-height: 1.5;
  display: block;
  position: absolute;
  left: -10px;
  top: -60px;
  transform: rotate(40deg);
  box-shadow: 3px -4px 13px 3px rgba(0,0,0,0.75)
}
.plan-highlight-best{
  left: -8px;
  line-height: 110%;
  top: -64px;
  font-size: 10px;
  color: white;
  padding: 60px 0px 5px 55px;
  background: #fa6e6f;
}

.qwoach-input{
  width: 60px;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #CCCCCC;
  color: #757575;
}

.special-deal-container{
  display: none;
}


.partner-qwoach{
  img{
    max-height: 40px;
  }
}

.logo-main {
  color: white;
  h1, h2, h3, h4, h5, h6 {
    color: white;
  }

}
.checkmark-placeholder:before {
  content: 'x';
  display: inline-block;
  color: green;
  font-size: 20px;
  padding: 0 6px 0 0;
  opacity: 0;
}
.checkmark:before {
  content: '\2713';
  display: inline-block;
  color: green;
  font-size: 20px;
  padding: 0 6px 0 0;
}
.checkmark-warning:before {
  content: '\2713';
  display: inline-block;
  color: orange;
  font-size: 20px;
  padding: 0 6px 0 0;
}
.closed-mark:before {
  content: 'x';
  display: inline-block;
  color: red;
  font-size: 20px;
  padding: 0 6px 0 0;
}

.strike-through{
  text-decoration: line-through
}

.body-scrolled [data-navbar=fixed] {
  background-color: rgba(255,255,255,.99);
  box-shadow: 0 1px 9px rgba(0,0,0,.05);
}

.accordion .card-title {
  //background-color: #4B7482 !important;
    color: #4B7482 !important;
}


.navbar {
  top: 0;
}

.navbar-scrolled {
  .logo-main{
    color: #757575;
    transition: transform .3s ease-out, color .3s ease-out;
  }
}

.countdown-sm, .countdown, .countdown-lg {
  color: #ffba00;
  h1, h2, h3, h4, h5, h6 {
    color: #ffba00;
  }
  text-align: center;
  h1, h2, h3, h4, h5, h6 {
    line-height: 35px;
    margin: 0;
  }
  .gap-x-4>*{
    margin: 0;
  }
  .col{
    padding: 5px;
  }
}


